import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import Typography from '../Typography';

const { H300 } = Typography;

const SecondaryBtn = styled.button`
 ${H300}
  background: transparent;
  text-decoration: none;
  display: inline-block;
  border: none;
  border-bottom: solid 3px ${props => props.theme.colors.orange800};
  box-sizing: border-box;
  transition: ${props => props.theme.transitions.hover};
  &:hover {
    cursor: pointer;
    border-bottom-width: 5px;
    margin-bottom: -2px;
  }
`;

export default SecondaryBtn;

