import TextFieldMui from '@material-ui/core/TextField';
import styled from 'styled-components';
import ErrorIcon from './ErrorIcon';
import ValidIcon from './ValidIcon';

const TextField = styled(TextFieldMui)`
  && {
    label {
      color: ${props => (props.error ? props.theme.colors.red800 : props.theme.colors.black400)};
      font-size: 16px;
    }
    div {
      fieldset {
        border-color: ${props =>
          props.error ? props.theme.colors.red800 : props.theme.colors.black200} !important;
          border-width: 2px;
      }
    }
    div {
    transition: ${props => props.theme.transitions.hover};
      &:hover {
        fieldset {
          border-color: ${props =>
          props.error ? props.theme.colors.red800 : props.theme.colors.black300} !important;
        }
      }
    }
    input {
      background: url("${props => (props.valid) === 'true' ? ValidIcon : (props.error) ? ErrorIcon : 'none'}");
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center right 16px;
      font-size: 16px;
    }
    textarea {
      font-size: 16px;
    }
  }
`;

export default TextField;
