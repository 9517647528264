import styled from "styled-components"
import MuiMenuItem from "@material-ui/core/MenuItem"

const MenuItem = styled(MuiMenuItem)`
  && {
    text-indent: 0;
    &::before {
      content: "";
      background: none;
      height: 0;
      width: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
`
export default MenuItem