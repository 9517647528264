const colors = {
  orange900: '#dc5f44',
  orange800: '#f46a4c',
  orange700: '#f68870',
  orange600: '#f79782',
  orange500: '#f8a694',
  orange400: '#fab5a6',
  orange300: '#fbc3b7',
  orange200: '#fcd2c9',
  orange100: '#fde1db',
  blue900: '#5a9eaf',
  blue800: '#64afc2',
  blue700: '#83bfce',
  blue600: '#93c7d4',
  blue500: '#a2cfda',
  blue400: '#b2d7e1',
  blue300: '#c1dfe7',
  blue200: '#d1e7ed',
  blue100: '#e0eff3',
  yellow900: '#e5af0c',
  yellow800: '#fec20d',
  yellow700: '#fece3d',
  yellow600: '#fed456',
  yellow500: '#feda6e',
  yellow400: '#ffe186',
  yellow300: '#ffe79e',
  yellow200: '#ffedb6',
  yellow100: '#fff3cf',
  black900: '#000000',
  black800: '#313131',
  black700: '#626262',
  black600: '#7b7b7a',
  black500: '#949492',
  black400: '#acacab',
  black300: '#c5c5c3',
  black200: '#dddddc',
  black100: '#f6f6f4',
  green900: '#00a964',
  green800: '#00d37d',
  green700: '#33dc97',
  green600: '#4de0a4',
  green500: '#66e5b1',
  green400: '#80e9be',
  green300: '#99edcb',
  green200: '#b3f2d8',
  green100: '#ccf6e5',
  red900: '#ba371a',
  red800: '#e94521',
  red700: '#ed6a4d',
  red600: '#f07d64',
  red500: '#f28f7a',
  red400: '#f4a290',
  red300: '#f6b5a6',
  red200: '#f8c7bc',
  red100: '#fbdad3',
  white: '#ffffff',
}

const primaryColors = {
  primary: colors.orange800,
  primaryBlack: colors.black900,
  blueSuccess: colors.blue800,
  redError: colors.red800,
  greenSuccess: colors.green800,
}

const boxShadow = {
  zIndex1: '0 1px 1px 0 rgba(0, 0, 0, 0.08)',
  zIndex5: '0 5px 10px 0 rgba(0, 0, 0, 0.08)',
}

const font = {
  primary: 'SAF',
  secondary: 'Montserrat, sans-serif',
}

const transitions = {
  hover: '0.2s ease-in;'
}

const theme = {
  colors,
  primaryColors,
  boxShadow,
  font,
  transitions,
};

export default theme;
