
import styled from 'styled-components';
import Typography from '../Typography';

const { H200 } = Typography;

const OptionalFormText = styled.p`
  ${H200}
  color: ${props => props.theme.colors.black400};
  text-align: right;
  margin-bottom: 0;
`;

export default OptionalFormText;