import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import HamburgerMenu from 'react-hamburger-menu';
import MuiDrawer from '@material-ui/core/Drawer';
import breakpoints from '../../styles/breakpoints';
import MobileNav from './MobileNav';
import Logo from '../Logo';
import NavbarItems from './NavbarItems';
import NavTop from './NavTop';
import ApplyNowDrawer from '../ApplyNowDrawer';
import { currentLocale } from "../../locale/languages"

const ShowNavbarOnDesktop = styled.div`
  display: none;
  ${breakpoints.lg`
    display: block;
  `};
`;

const ShowNavbarOnMobile = styled.div`
  display: block;
  ${breakpoints.lg`
    display: none;
  `};
`;

const NavbarContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.boxShadow.zIndex1};
  padding: 0 16px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1100;
  box-sizing: border-box;
  flex-grow: 1;
  flex-direction: row;
  min-height: 56px;
  width: 100%;

  ${breakpoints.lg`
    z-index: 999;
    overflow-x: hidden;
    width: auto;
    position: sticky;
    padding: 0 0 0 16px;
    min-height: 64px;
  `};
  .slide-left {
    transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: ${props =>
      props.stickyNavbar ? `translateX(-${props.slideWidth}px)` : `translateX(0)`};
  }
  .slide-in {
    display: none;
    transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    position: absolute;
    top: 0;
    right: 0;
    transform: ${props => (props.stickyNavbar ? 'translateX(0)' : 'translateX(100%)')};
    ${breakpoints.lg`
      display: block;
  `};
  }
`;

const Drawer = styled(MuiDrawer)`
  z-index: 1099;
`

const Brand = styled.div`
  position: fixed;
  top: 8px;
  left: 16px;
  width: 54px;
  height: 64px;
  transition: 0.3s ease 0s;
  z-index: 1199;

  ${breakpoints.md`
    top: 12px;
    left: 48px;
    width: ${props => (props.stickyNavbar ? '54px' : '68px')};
    height: ${props => (props.stickyNavbar ? '64px' : '80px')};
  `};
`;

class Navbar extends React.PureComponent {
  constructor() {
    super();
    this.applyButtonRef = React.createRef();
  }

  state = {
    open: false,
    stickyNavbar: false,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleOnScroll);
    this.applyNowButtonWidth = this.applyButtonRef.current.clientWidth || '0';
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleOnScroll);
  };

  handleOnScroll = () => {
    const wScroll = window.scrollY;

    if (wScroll >= 40) {
      this.setState({
        stickyNavbar: true,
      });
    }
    if (wScroll <= 40) {
      this.setState({
        stickyNavbar: false,
      });
    }
  };

  handleClick() {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  render() {
    const { open, stickyNavbar } = this.state;
    const { pageContext } = this.props;
    const setLocaleInUrl = pageContext.locale && currentLocale !== pageContext.locale ? `${pageContext.locale}/` : ""

    return (
      <React.Fragment>
        <ShowNavbarOnDesktop>
          <NavTop locale={pageContext.locale} />
        </ShowNavbarOnDesktop>
        <Link to={`/${setLocaleInUrl}`}>
          <Brand stickyNavbar={stickyNavbar}>
            <Logo logoTheme="Dark" />
          </Brand>
        </Link>
        <NavbarContainer stickyNavbar={stickyNavbar} slideWidth={this.applyNowButtonWidth}>
          <ShowNavbarOnDesktop>
            <div className="slide-left">
              <NavbarItems pageContext={pageContext} />
            </div>
            <div className="slide-in" ref={this.applyButtonRef}>
              <ApplyNowDrawer locale={pageContext.locale} />
            </div>
          </ShowNavbarOnDesktop>
          <ShowNavbarOnMobile>
            <HamburgerMenu
              isOpen={open}
              menuClicked={() => this.handleClick()}
              width={24}
              height={16}
              strokeWidth={2}
              rotate={0}
              color="black"
              borderRadius={3}
              animationDuration={0.5}
            />
          </ShowNavbarOnMobile>
        </NavbarContainer>
        <ShowNavbarOnMobile>
          <Drawer anchor="top" open={open} onClose={() => this.handleClick()}>
            <MobileNav pageContext={pageContext} onNavigate={() => this.handleClick()} />
          </Drawer>
        </ShowNavbarOnMobile>
      </React.Fragment>
    );
  }
}

export default Navbar;
