import SelectMui from "@material-ui/core/Select"
import styled from "styled-components"

const SelectField = styled(SelectMui)`
  && {
    fieldset {
      color: ${props => props.theme.colors.black200};
      border-color: ${props => props.theme.colors.black200};
      border-width: 2px;
    }
    &:hover {
      fieldset {
        border-color: ${props => props.theme.colors.black300} !important;
      }
    }
    svg {
      display: none;
    }
  }
`

export default SelectField
