import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import { FacebookIcon, InstagramIcon, YoutubeIcon } from "../Icons"

const Container = styled.div`
  display: inline-block;
`

const SocialLinks = ({ facebook, instagram, youtube }) => (
  <Container>
    <Grid container alignItems="center">
      {facebook && (
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FacebookIcon width="32" height="32" margin="0 16px 0 0" />
        </a>
      )}
      {instagram && (
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <InstagramIcon width="32" height="32" margin="0 16px 0 0"/>
        </a>
      )}
      {youtube && (
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <YoutubeIcon width="24" height="24" />
        </a>
      )}
    </Grid>
  </Container>
)

export default SocialLinks
