import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { useCookies } from "react-cookie"
import styled from "styled-components"
import Card from "../Card"
import SecondaryBtn from "../Buttons/SecondaryBtn"
import breakpoints from "../../styles/breakpoints"
import { CloseIcon, CookieIcon } from "../Icons"
import localeData from "../../locale"

const CookiesContainer = styled(Card)`
  /* display: ${props =>
    typeof props.CookieSet !== "undefined" ? "none" : "block"}; */
  display: ${props => (props.showCookie ? "flex" : "none")};
  position: fixed;
  padding: 16px;
  bottom: 16px;
  right: 16px;
  left: 16px;
  z-index: 998;
  box-shadow: ${props => props.theme.boxShadow.zIndex5};
  button {
    margin-top: 16px;
    display: block;
  }

  ${breakpoints.md`
    padding: 24px;
    max-width: 384px;
    bottom: 48px;
    right: 48px;
    left: unset;
    button {
      display: inline-block;
      margin-right: 16px;
    }
  `};
`
const Column = styled.div`
  svg {
    margin-right: 16px;
  }
`

// const CloseButton = styled.div`
//   position: absolute;
//   cursor: pointer;
//   top: 16px;
//   right: 16px;
// `

const Cookies = ({ locale, header, content }) => {
  const [cookies, setCookie] = useCookies(["AllowGaTracking"])
  const [showCookie, setShowCookie] = useState(false)

  const allowTracking = () => {
    setCookie("AllowGaTracking", "true", { path: "/" })
    setShowCookie(false)
  }

  useEffect(() => {
    if (
      typeof cookies.AllowGaTracking === "undefined" &&
      cookies.AllowGaTracking !== "true"
    ) {
      setShowCookie(true)
    }
  }, [])
  return (
    <CookiesContainer showCookie={showCookie}>
      <Column>
        <CookieIcon />
      </Column>
      <Column>
        {/* <CloseButton onClick={() => setShowCookie(false)}>
          <CloseIcon />
        </CloseButton> */}
        <strong>{header[locale]}</strong>
        <p>{content[locale]}</p>
        <SecondaryBtn onClick={allowTracking}>
          {localeData._rawCookiesBtn[locale]}
        </SecondaryBtn>
      </Column>
    </CookiesContainer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query cookiesContent {
        sanitySiteSettings {
          header: _rawCookiesHeader
          content: _rawCookiesText
        }
      }
    `}
    render={data => (
      <Cookies
        header={data.sanitySiteSettings.header}
        content={data.sanitySiteSettings.content}
        {...props}
      />
    )}
  />
)
