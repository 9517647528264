import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import LanguageSelector from "../../LanguageSelector"
import { WhatsAppIcon } from "../../Icons"

const Container = styled.div`
  background-color: ${props => props.theme.colors.black100};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  padding: 0 48px;
`

const Contact = styled.a`
  color: ${props => props.theme.colors.black700};
  text-align: center;
  padding: 0 16px;
  display: flex;
  align-items: center;

  &:hover {
    color: ${props => props.theme.colors.black400};
  }
`

const NavTop = ({ data, locale }) => (
  <Container>
    {data.phone && (
      <Contact href={`tel:${data.phone}`}>
        <WhatsAppIcon margin="0 8px 0 8px" />
        {data.phone}
      </Contact>
    )}
    {data.email && (
      <Contact href={`mailto:${data.email}`}>{data.email}</Contact>
    )}
    <LanguageSelector locale={locale} />
  </Container>
)

export default props => (
  <StaticQuery
    query={graphql`
      query navTop {
        sanityCompanyInfo {
          phone
          email
        }
      }
    `}
    render={data => <NavTop data={data.sanityCompanyInfo} {...props} />}
  />
)
