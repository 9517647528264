import React from 'react';
import styled from 'styled-components';

const Spacer = styled.div`
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  display: flex;
  align-items: ${props => (props.alignItems === 'center' ? 'center' : 'normal')};
`;

const Vspacer = ({ children, marginTop, marginBottom, paddingTop, paddingBottom, alignItems }) => (
  <Spacer
    marginTop={marginTop}
    marginBottom={marginBottom}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    alignItems={alignItems}
  >
    {children}
  </Spacer>
);

export default Vspacer;
