import React from "react"
import styled from "styled-components"
import { TailSpin } from "react-loader-spinner"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  &::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
  }
`
const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Loader = () => (
  <Container>
    <LoaderContainer>
      <TailSpin
        height="100"
        width="100"
        color="#fec20d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </LoaderContainer>
  </Container>
)

export default Loader
