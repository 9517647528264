import styled from 'styled-components';
import { Link } from 'gatsby';
import Typography from '../Typography';

const { H400, H300, H200 } = Typography;

export const LinkH400 = styled(Link)`
  ${H400}  
  color: ${props => props.theme.colors.black900};
  text-decoration: none;
`;

export const LinkH300 = styled(Link)`
  ${H300}
  color: ${props => props.theme.colors.black900};
  text-decoration: none;
`;

export const LinkH200 = styled(Link)`
  ${H200}
  text-decoration: none;
`;

export const LinkH200Normal = styled.a`
  ${H200}
  text-decoration: none;
`;

export const LinkUnderline = styled(Link)`
  ${H300}
  text-decoration: none;
  display: inline-block;
  border-bottom: solid 3px ${props => props.theme.colors.orange800};
  box-sizing: border-box;
  transition: ${props => props.theme.transitions.hover};
  &:hover {
    border-bottom-width: 5px;
    margin-bottom: -2px;
  }
`;

export const ExternalLinkUnderline = styled.a`
  ${H300}
  display: inline-block;
  border-bottom: solid 3px ${props => props.theme.colors.orange800};
  transition: ${props => props.theme.transitions.hover};
  &:hover {
    border-bottom-width: 5px;
    margin-bottom: -2px;
  }
`;