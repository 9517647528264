import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { LinkH300 } from "../../NormalLink"

import { currentLocale } from "../../../locale/languages"

// import NavBarSubItems from "./NavBarSubItems"

import slugify from "../../../utils/slugify"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
`

const Item = styled(LinkH300)`
  padding: 0 16px;
  &.active {
    color: ${props => props.theme.colors.orange800};
    &:hover {
      color: ${props => props.theme.colors.orange800};
    }
  }
  &:hover {
    color: ${props => props.theme.colors.black400};
  }
`

const NavbarItems = ({ data, pageContext }) => {
  const { locale } = pageContext
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""
  const navItems = [data.sanityAllVacanciesPage, data.sanityOurProcessPage, data.sanityAboutUsPage, data.sanityStoriesPage, data.sanityFaqPage, data.sanityContactPage];
  return (
    <Container>
      {(navItems && locale) && navItems.map(item => (
        <Item
          key={`${item.id}`}
          to={`/${setLocaleInUrl}${slugify(item._rawLocaleTitle[locale])}`}
          activeClassName="active"
        >
          {item._rawLocaleTitle[locale]}
        </Item>
      ))}
    </Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query navItems {
        sanityAllVacanciesPage {
          id
          _rawLocaleTitle
        }
        sanityOurProcessPage {
          id
          _rawLocaleTitle
        }
        sanityAboutUsPage {
          id
          _rawLocaleTitle
        }
        sanityStoriesPage {
          id
          _rawLocaleTitle
        }
        sanityFaqPage {
          id
          _rawLocaleTitle
        }
        sanityContactPage {
          id
          _rawLocaleTitle
        }
      }
    `}
    render={data => <NavbarItems data={data} {...props} />}
  />
)
