const vSpacing = {
  base: '8px',
  baseX2: '16px',
  baseX3: '24px',
  baseX4: '32px',
  baseX5: '40px',
  baseX6: '48px',
  baseX7: '56px',
  baseX11: '88px',
  baseX15: '120px',
}

export default vSpacing;