import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import breakpoints from '../../styles/breakpoints';
import localeData from "../../locale"

const { H200Semibold } = Typography;

const ApplyNow = styled.div`
  ${H200Semibold}
  color: ${props => props.theme.colors.white};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.orange800};
  text-transform: uppercase;
  min-height: 56px;
  padding: 0 24px;
  max-height: 64px;
  transition: ${props => props.theme.transitions.hover};
  
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.orange900};
  }
  ${breakpoints.md`
    min-height: 64px;
    padding: 24px;
  `};
`;

const ApplyNowBtn = ({ click, locale }) => <ApplyNow onClick={click}>{localeData._rawApplyNow[locale]}</ApplyNow>;

export default ApplyNowBtn;
