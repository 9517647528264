import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Menu from "@material-ui/core/Menu"
import MenuItem from "../MenuItem"
import LanguageMap from "./LanguageMap"
import FlagSelector from "./FlagSelector"
import Card from "../Card"
import breakpoints from "../../styles/breakpoints"
import Typography from "../Typography"
import ShowMobileOnly from "../ShowMobileOnly"
import { DownChevron } from "../Icons"

import { currentLocale, extraLanguages } from "../../locale/languages"

const { P } = Typography

const SelectorContainer = styled(Card)`
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0 4px 0;
  ${breakpoints.md`
    margin: 0;
  `};
  &:hover {
    cursor: pointer;
  }
`

const Language = styled(P)`
  padding: 0 12px;
`

const DownChevronContainer = styled.div`
  padding-left: 8px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  ${breakpoints.md`
    position: static;
  `};
`

class LanguageSelector extends React.PureComponent {
  state = {
    anchorEl: null,
    activeLocale: currentLocale,
  }

  componentDidMount() {
    const { locale } = this.props
    this.setState({ activeLocale: locale })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleLanguangeClick = language => {
    this.setState({ anchorEl: null, activeLocale: language })
    if(language === currentLocale) {
      navigate(`/`)
    } else {
      navigate(`/${language}`)
    }
  }

  render() {
    const { anchorEl, activeLocale } = this.state
    return (
      <React.Fragment>
        <SelectorContainer onClick={this.handleClick}>
          <FlagSelector flag={activeLocale} />
          <ShowMobileOnly>
            <Language>{LanguageMap[activeLocale]}</Language>
          </ShowMobileOnly>
          <DownChevronContainer>
            <DownChevron width="8px" height="8px" />
          </DownChevronContainer>
        </SelectorContainer>
        <Menu
          id="dropdown"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={() => this.handleLanguangeClick(currentLocale)}
          >
            <FlagSelector flag={currentLocale} />
            <Language>{LanguageMap[currentLocale]}</Language>
          </MenuItem>
          {extraLanguages.map(language => (
            <MenuItem
              onClick={() => this.handleLanguangeClick(language)}
              key={`languages-${language}`}
            >
              <FlagSelector flag={language} />
              <Language>{LanguageMap[language]}</Language>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )
  }
}

export default LanguageSelector
