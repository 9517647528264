import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.boxShadow.zIndex1};
  min-height: 56px;
  padding: 0 16px;
  border-radius: 3px;
  ${breakpoints.md`
    padding: 0 8px;
    min-height: 28px;
  `};
`;

export default Card;
