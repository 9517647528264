import styled from 'styled-components';
import theme from '../../styles/theme';

const H800 = `
  font-family: ${theme.font.primary};
  color: ${theme.primaryColors.primaryBlack};
  font-size: 3.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
`;

const H700 = `
  font-family: ${theme.font.primary};
  color: ${theme.colors.black900};
  font-size: 2.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  `;

const H600 = `
  font-family: ${theme.font.primary};
  color: ${theme.colors.black900};
  font-size: 2.4rem;  
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  `;

const H400 = `
  font-family: ${theme.font.secondary};
  color: ${theme.colors.black700};
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  `;

const H400Semibold = `
  ${H400}
  font-weight: 600;
`;

const H300 = `
  font-family: ${theme.font.secondary};
  color: ${theme.colors.black900};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  `;
  
const H300Semibold = `
  ${H300}
  font-weight: 600;
`;

const H200 = `
  font-family: ${theme.font.secondary};
  color: ${theme.colors.black900};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  `;

const H200Semibold = `
  ${H200}
  font-weight: 600;
`;

const H1 = styled.h1`
  ${H800}  
  margin: 8px 0;
`;

const P = styled.p`
  ${H300}
  margin: 4px 0;
`;

const Typography = {
  H800,
  H700,
  H600,
  H400,
  H300,
  H400Semibold,
  H300Semibold,
  H200Semibold,
  H200,
  H1,
  P,
};

export default Typography;
