/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { CookiesProvider } from "react-cookie"
import styled, { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Cookies from "./Cookies"
import breakpoints from "../styles/breakpoints"
import GlobalStyle from "../styles/globalStyles"

const Main = styled.div`
  padding-top: 56px;
  position: relative;
  ${breakpoints.md`
    padding-top: 0;
  `};
`

const Layout = ({ children, pageContext }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <div>
              <GlobalStyle />
              <Navbar pageContext={pageContext} />
              <Main>{children}</Main>
              <Footer pageContext={pageContext} />
              <Cookies locale={pageContext.locale} />
            </div>
          </ThemeProvider>
        </CookiesProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
