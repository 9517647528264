import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const ShowMobileOnly = styled.div`
  display: block;
  ${breakpoints.md`
    display: none;
  `};
`;

export default ShowMobileOnly;
