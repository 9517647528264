import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Reaptcha from 'reaptcha';
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import muiInputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import axios from "../../utils/axios"
import TextField from "../InputFields/TextField"
import SelectField from "../InputFields/SelectField"
import ErrorMessage from "../ErrorMessage"
import checkValidity from "../../utils/checkValidity"
import PrimaryBtn from "../Buttons/PrimaryBtn"
import SuccesBackground from "../UI/SuccesBackground"
import OptionalFormText from "../UI/OptionalFormText"
import { DownChevron } from "../Icons"
import localeData from "../../locale"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"
import Loader from "../Loader"

const InputContainer = styled.div`
  margin-bottom: 32px;
  .form-control-select {
    width: 100%;
    label {
      color: ${props => props.theme.colors.black400};
    }
  }
`

const SelectionInput = styled.div`
  margin-top: 8px;
`
const SelectMenuItem = styled(MenuItem)`
  && {
    &::before {
      content: "";
      background: none;
      height: 0;
      width: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
`
const InputLabel = styled(muiInputLabel)`
  && {
    background-color: white;
  }
`

const DownChevronContainer = styled.div`
  padding-left: 8px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
`

class ApplyNowForm extends React.PureComponent {
  state = {
    formData: {
      name: {
        id: "name",
        elementConfig: {
          type: "name",
        },
        value: "",
        validation: {
          required: true,
        },
        error: false,
        errorMessage: "Please fill in your name",
        valid: false,
        touched: false,
      },
      email: {
        id: "email",
        elementConfig: {
          type: "email",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        error: false,
        errorMessage: "Please fill in your valid e-mail address",
        valid: false,
        touched: false,
      },
      phone: {
        id: "phone",
        elementType: "input",
        elementConfig: {
          type: "number",
        },
        validation: {
          required: false,
        },
        value: "",
        touched: false,
      },
      message: {
        id: "message",
        elementConfig: {
          type: "message",
        },
        value: "",
        validation: {
          required: true,
        },
        error: false,
        errorMessage: "Please fill in your message",
        valid: false,
        touched: false,
      },
      vacancySelect: {
        value: "",
        validation: {
          required: false,
        },
      },
    },
    formIsValid: false,
    reCaptchaPassed: false,
    loading: false,
    succes: false,
    submitFailed: false,
  }

  componentDidMount() {
    const { selectedVacancy } = this.props
    if(selectedVacancy) {
      const { formData } = this.state
      const newFormData = {
        ...formData,
      }
      newFormData.vacancySelect.value = selectedVacancy
      this.setState({ formData: newFormData })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ loading: true })
    const submitFormData = {}
    const { formData } = this.state
    for (const formElement in formData) {
      submitFormData[formElement] = formData[formElement].value
    }

    axios
      .post("/apply-now", submitFormData)
      .then(response => {
        console.log('submit success');
        this.setState({ loading: false, succes: true, submitFailed: false,  })
        window.scrollTo(0, 0)
        trackCustomEvent({
          category: "Apply now Formulier",
          action: "Verstuurd",
          label: formData.vacancySelect.value ? `categorie: ${formData.vacancySelect.value}` :  'geen categorie gekozen',
        })
      })
      .catch(error => {
        console.log('submit failed');
        this.setState({ loading: false, submitFailed: true })
        window.scrollTo(0, 0)
      })
  }

  handleReCAPTCHAValid = () => {
    this.setState({
      reCaptchaPassed: true
    });
  };

  handleChange(e, key) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
    }

    newFormData[key].value = e.target.value
    newFormData[key].valid = checkValidity(
      newFormData[key].value,
      newFormData[key].validation
    )
    newFormData[key].touched = true
    if (newFormData[key].valid) {
      newFormData[key].error = false
    }

    let formIsValid = true
    for (const formElement in newFormData) {
      if (
        newFormData[formElement].validation.required &&
        !newFormData[formElement].valid
      ) {
        formIsValid = false
      }
    }
    this.setState({ formData: newFormData, formIsValid })
  }

  handleBlur(key) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
    }
    if (!formData[key].valid) {
      newFormData[key].error = true
      this.setState({ formData: newFormData })
    } else {
      newFormData[key].error = false
      this.setState({ formData: newFormData })
    }
  }

  handleSelectChange(e) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
    }
    newFormData[e.target.name].value = e.target.value
    this.setState({ formData: newFormData })
  }

  handleReset(e) {
    e.preventDefault()
    this.setState({ submitFailed: false })
  }

  render() {
    const { formData, formIsValid, submitFailed, reCaptchaPassed, loading, succes } = this.state
    const { email, name, phone, vacancySelect, message } = formData
    const { data, locale, companyEmail } = this.props
    const submitEnabled = formIsValid && reCaptchaPassed

    let form = (
      <form onSubmit={this.handleSubmit}>
        <InputContainer>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            margin="dense"
            label="Name"
            fullWidth
            value={name.value}
            onChange={e => this.handleChange(e, "name")}
            onBlur={() => this.handleBlur("name")}
            error={name.error}
            valid={name.valid.toString()}
          />
          <ErrorMessage error={name.error} errorMessage={name.errorMessage} />
        </InputContainer>
        <InputContainer>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            margin="dense"
            label="Email"
            fullWidth
            value={email.value}
            onChange={e => this.handleChange(e, "email")}
            onBlur={() => this.handleBlur("email")}
            error={email.error}
            valid={email.valid.toString()}
          />
          <ErrorMessage error={email.error} errorMessage={email.errorMessage} />
        </InputContainer>
        <InputContainer>
          <OptionalFormText>
            {localeData._rawOptional[locale]}
          </OptionalFormText>
          <TextField
            id="phone"
            name="phone"
            variant="outlined"
            margin="dense"
            type="tel"
            label="Phone"
            fullWidth
            value={phone.value}
            onChange={e => this.handleChange(e, "phone")}
            onBlur={() => this.handleBlur("phone")}
          />
        </InputContainer>
        {data && (
          <InputContainer>
            <OptionalFormText>
              {localeData._rawOptional[locale]}
            </OptionalFormText>
            <SelectionInput>
              <FormControl variant="outlined" className="form-control-select">
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {localeData._rawVacancyTitle[locale]}
                </InputLabel>
                <SelectField
                  value={vacancySelect.value}
                  onChange={e => this.handleSelectChange(e)}
                  input={<OutlinedInput labelWidth={0} name="vacancySelect" />}
                >
                  <SelectMenuItem value={localeData._rawVacancyNoOption[locale]}>
                    <em>{localeData._rawVacancyNoOption[locale]}</em>
                  </SelectMenuItem>
                  {data.map(vacancy => (
                    <SelectMenuItem
                      value={vacancy.node.title[locale]}
                      key={vacancy.node.id}
                    >
                      {vacancy.node.title[locale]}
                    </SelectMenuItem>
                  ))}
                </SelectField>
                <DownChevronContainer>
                  <DownChevron width="12px" height="12px" />
                </DownChevronContainer>
              </FormControl>
            </SelectionInput>
          </InputContainer>
        )}

        <InputContainer>
          <TextField
            id="message"
            name="message"
            variant="outlined"
            margin="dense"
            label="Message"
            multiline
            rows="6"
            rowsMax="10"
            fullWidth
            value={message.value}
            onChange={e => this.handleChange(e, "message")}
            onBlur={() => this.handleBlur("message")}
            error={message.error}
            valid={message.valid.toString()}
          />
          <ErrorMessage
            error={message.error}
            errorMessage={message.errorMessage}
          />
        </InputContainer>
        <Vspacer marginBottom={vSpacing.baseX6}>
          <Reaptcha sitekey={process.env.GATSBY_RECAPTCHA_KEY} onVerify={this.handleReCAPTCHAValid} hl={locale} />
        </Vspacer>
        <PrimaryBtn type="submit" disabled={!submitEnabled}>
          {localeData._rawSendBtnText[locale]}
        </PrimaryBtn>
      </form>
    )

    if (loading) {
      form = <Loader />
    }
    if (succes) {
      form = (
        <SuccesBackground>
          <p>
            {localeData._rawThankYouMessage[locale]}
          </p>
        </SuccesBackground>
      )
    }
    if (submitFailed) {
      form = (
        <div>
          <p>
            {localeData._rawFailedMessage[locale]}
            {" "}
            <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
            {" "}
          </p>
          <PrimaryBtn onClick={e => this.handleReset(e)}>
            {localeData._rawTryAgain[locale]}
          </PrimaryBtn>
        </div>
      )
    }
    return <React.Fragment>{form}</React.Fragment>
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query vacanciesApplyNow {
        allSanityVacancy {
          edges {
            node {
              id
              title: _rawLocaleTitle
            }
          }
        }
        sanityCompanyInfo {
          email
        }
      }
    `}
    render={data => (
      <ApplyNowForm data={data.allSanityVacancy.edges} companyEmail={data.sanityCompanyInfo.email} {...props} />
    )}
  />
)
