import * as localeData from './locale'

export default localeData.default

// DEPRECATED IN FAVOR OF locale.json
// const localeData = {
//   exploreVacancyBtn: {
//     nl: 'Ontdek onze vacatures',
//     en: 'Explore our vacancies',
//     de: ''
//   },
//   howItWorks: {
//     nl: 'Zie hoe het werkt',
//     en: 'See how it works',
//     de: ''
//   },
//   socialButton: {
//     nl: "Delen",
//     en: "Share",
//     de: ''
//   },
//   applyNow: {
//     nl: "Aanmelden",
//     en: "Apply now",
//     de: ''
//   },
//   ContactUs:{
//     nl: "Neem contact op",
//     en: "Contact us",
//     de: ''
//   },
//   requirements: {
//     nl: 'Vacature eisen',
//     en: 'Vacancy requirements',
//     de: ''
//   },
//   vacancyNavigationContent: {
//     projectDescription: {
//       nl: "Project omschrijving",
//       en: "Project description",
//       de: ''
//     },
//     theVacancy: {
//       nl: "De vacature",
//       en: "The vacancy",
//       de: ''
//     },
//     facilities: {
//       nl: "De faciliteiten",
//       en: "The facilities",
//       de: ''
//     },
//     costs: {
//       nl: "De kosten",
//       en: "The costs",
//       de: ''
//     },
//   },
//   ContactTeamTitle: {
//     nl: "Neem contact op met het team",
//     en: "Contact the team",
//     de: ''
//   },
//   faq: {
//     title: {
//       nl: 'frequently asked questions',
//       en: 'frequently asked questions',
//       de: ''
//     },
//     more: {
//       nl: 'Bekijk alle frequently asked questions',
//       en: 'View all frequently asked questions',
//       de: ''
//     },
//     filterTitle: {
//       nl: 'Filter op categorie',
//       en: 'Filter by category',
//       de: ''
//     },
//     faqHelpTitle: {
//       nl: 'Kun je niet vinden wat je zoekt?',
//       en: 'Can\'t find what you\'re looking for?',
//     de: ''

//     },
//     faqHelpText: {
//       nl: 'We helpen graag',
//       en: 'We are glad to help',
//     de: ''

//     },

//   },
//   phone: {
//     nl: 'Telefoon',
//     en: 'Phone',
//     de: ''

//   },
//   mail: {
//     nl: 'Mail',
//     en: 'Mail',
//     de: ''

//   },
//   stories: {
//     title: {
//       nl: 'Stories',
//       en: 'Stories',
//     de: ''

//     },
//     more: {
//       nl: 'Lees alle Stories',
//       en: 'Read all Stories',
//     de: ''

//     },
//     readMore: {
//       nl: 'Lees meer Stories',
//       en: 'Read more Stories',
//     de: ''

//     },
//   },
//   vacancies: {
//     allVacancyTitle: {
//       nl: 'Kies project',
//       en: 'Choose your project',
//     de: ''

//     },
//     readMore: {
//       nl: 'Lees meer vacatures',
//       en: 'Read more vacancies',
//     de: ''

//     },
//     popular: {
//       nl: 'Recente vacatures',
//       en: 'Recent vacancies',
//     de: ''

//     },
//     destinationVacancies: {
//       nl: 'Recente vacatures uit',
//       en: 'Recent vacancies in',
//     de: ''

//     },
//     vacancyTitle: {
//       nl: 'Vacature',
//       en: 'Vacancy',
//     de: ''

//     },
//     vacancyNoOption: {
//       nl: 'Weet ik nog niet',
//       en: 'I don\'t know yet',
//     de: ''

//     }
//   },
//   readMore: {
//     nl: 'Lees meer',
//     en: 'Read more',
//     de: ''

//   },
//   moreVacancies: {
//     nl: 'Meer vacatures',
//     en: 'More vacancies',
//     de: ''

//   },
//   viewVacancies: {
//     nl: 'Bekijk alle vacatures',
//     en: 'View all vacancies',
//     de: ''

//   },
//   countryTitle: {
//     nl: 'Land',
//     en: 'Country',
//     de: ''

//   },
//   categoryTitle: {
//     nl: 'Categorie',
//     en: 'Category',
//     de: ''

//   },
//   startingPeriodTitle: {
//     nl: 'Start periode',
//     en: 'Starting period',
//     de: ''

//   },
//   vacancyMinimumLengthOfStayTitle: {
//     nl: 'Minimale verblijfsduur',
//     en: 'Minimum length of stay',
//     de: ''

//   },
//   numberOfPeople: {
//     nl: 'Aantal personen',
//     en: 'Number of people',
//     de: ''

//   },
//   studyFieldsTitle: {
//     nl: 'Vakgebieden',
//     en: 'Study fields',
//     de: ''
//   },
//   projectDetailsTitle: {
//     nl: 'Project details',
//     en: 'Project details',
//     de: ''

//   },
//   readMoreAboutUs: {
//     nl: 'Lees meer over ons',
//     en: 'Read more about us',
//     de: ''

//   },
//   stats: {
//     differentVacanciesTitle: {
//       nl: 'Verschillende vacatures en projecten',
//       en: 'Different vacancies and projects',
//     de: ''

//     },
//     partnersTitle: {
//       nl: 'Partners op alle locaties',
//       en: 'Partners accross all locations',
//     de: ''

//     },
//     peopleTitle: {
//       nl: 'Mensen gingen je al voor',
//       en: 'People already went before you',
//     de: ''

//     }
//   },
//   cookiesBtn: {
//     nl: 'Ik begrijp het',
//     en: 'Got it',
//     de: ''

//   },
//   form: {
//     optional: {
//       nl: 'Optioneel',
//       en: 'Optional',
//     de: ''

//     }
//   },
//   allLocations: {
//     nl: 'Alle locaties',
//     en: 'All locations',
//     de: ''

//   },
//   allCategories: {
//     nl: 'Alle categorieën',
//     en: 'All categories',
//     de: ''

//   },
//   noVacancies: {
//     nl: 'Geen vacatures',
//     en: 'No vacancies',
//     de: ''

//   },
//   interestedTitle: {
//     nl: "Ben je geïnteresseerd of heb je een vraag?",
//     en: "Are you interested or do you have a question?",
//     de: ''

//   },
//   interestedText: {
//     nl: "Vul het onderstaande formulier in en we nemen zo snel mogelijk contact met je op.",
//     en: "Fill out the form below and we’ll get back to you as soon as possible.",
//     de: ''

//   },
//   thankYouMessage: {
//     nl: "Bedankt voor je bericht! We nemen zo snel mogelijk contact met je op.",
//     en: "Thank you for your message! We will get back to you as soon as possible.",
//     de: ''

//   },
//   failedMessage: {
//     nl: "Er is iets mis gegaan, probeer opnieuw of stuur een e-mail direct naar:",
//     en: "Something went wrong, try again or send an e-mail directly to:",
//     de: ''

//   },
//   sendBtnText: {
//     nl: "Versturen",
//     en: "Send",
//     de: ''

//   },
//   tryAgain: {
//     nl: "Probeer opnieuw",
//     en: "Try again",
//     de: ''

//   },
//   CancellationPolicy: {
//     nl: "Annuleringsbeleid",
//     en: "Cancellation policy",
//     de: ''
//   }
// }
