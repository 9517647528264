import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';

const { H300 } = Typography;

const Message = styled.p`
  ${H300};
  color: ${props => props.theme.colors.red800};
`;

const ErrorMessage = ({ error, errorMessage }) => error && <Message>{errorMessage}</Message>;

export default ErrorMessage;
