import React from 'react';
import { EnglishFlag, DutchFlag, GermanFlag } from '../Icons';


const FlagSelector = ({ flag }) => {

  switch(flag) {
    case "nl":
        return <DutchFlag height="18" width="18" />
    case "en":
        return <EnglishFlag height="18" width="18" />
    case "de":
        return <GermanFlag height="18" width="18" />
    default:
      return null;
}

};

export default FlagSelector;
