import React from "react"
import muiDrawer from "@material-ui/core/Drawer"
import styled from "styled-components"

import breakpoints from "../../styles/breakpoints"
import ApplyNowBtn from "../ApplyNowBtn"
import ApplyNowForm from "../ApplyNowForm"
import PrimaryBtn from "../Buttons/PrimaryBtn"

import Typography from "../Typography"
import { CloseIcon } from "../Icons"
import localeData from "../../locale"

const { H700 } = Typography

const Title1 = styled.h2`
  ${H700}
  color: ${props => props.theme.primaryColors.primary};
`

const ApplyNowFormContainer = styled.div`
  margin-top: 104px;
  width: calc(100vw - 32px);
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 48px;
  ${breakpoints.md`
    width: 40vw;
    margin-top: 96px;
    margin-left: 96px;
    margin-right: 96px;
  `};
`

const Drawer = styled(muiDrawer)`
  && {
    z-index: 1100;
  }
`

const Header = styled.div`
  margin-bottom: 48px;
`

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
`

class ApplyNowDrawer extends React.PureComponent {
  state = {
    drawer: false,
  }

  toggleDrawer = open => () => {
    this.setState({
      drawer: open,
    })
  }

  render() {
    const { drawer } = this.state
    const { locale, btn, selectedVacancy } = this.props
    return (
      <React.Fragment>
        {btn === "Primary" ? (
          <PrimaryBtn onClick={this.toggleDrawer(true)}>
            {localeData._rawApplyNow[locale]}
          </PrimaryBtn>
        ) : (
          <ApplyNowBtn click={this.toggleDrawer(true)} locale={locale} />
        )}
        <Drawer anchor="right" open={drawer} onClose={this.toggleDrawer(false)}>
          <ApplyNowFormContainer>
            <CloseButton onClick={this.toggleDrawer(false)}>
              <CloseIcon />
            </CloseButton>
            <Header>
              <Title1>{localeData._rawInterestedTitle[locale]}</Title1>
              <p>
                {localeData._rawInterestedText[locale]}
              </p>
            </Header>
            <ApplyNowForm locale={locale} selectedVacancy={selectedVacancy} />
          </ApplyNowFormContainer>
        </Drawer>
      </React.Fragment>
    )
  }
}

export default ApplyNowDrawer
