import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { LinkH400 } from "../../NormalLink"
import LanguageSelector from "../../LanguageSelector"
import ApplyNowDrawer from "../../ApplyNowDrawer"
import { currentLocale } from "../../../locale/languages";

import slugify from "../../../utils/slugify"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 80px;
  background-color: ${props => props.theme.colors.white};
`

const Item = styled(LinkH400)`
  padding: 16px;
  text-align: center;
  display: block;
  &.active {
    color: ${props => props.theme.colors.orange800};
    &:hover {
      color: ${props => props.theme.colors.orange800};
    }
  }
  &:hover {
    color: ${props => props.theme.colors.black400};
  }
`
const Footer = styled.div`
  background-color: ${props => props.theme.colors.black100};
  padding: 28px 16px;
`

const Contact = styled.a`
  color: ${props => props.theme.colors.black400};
  text-align: center;
  display: block;
  margin-bottom: 4px;
`

const MobileNav = ({ data, pageContext, onNavigate }) => {
  const { locale } = pageContext;
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : '';
  // const navItem = data.allSanityMainMenu.edges;
  const { sanityCompanyInfo } = data;
  const navItems = [data.sanityAllVacanciesPage, data.sanityOurProcessPage, data.sanityAboutUsPage, data.sanityStoriesPage, data.sanityFaqPage, data.sanityContactPage];

  return (
    <Container>
      {navItems.map(item => (
        <Item
          key={`${item.id}`}
          to={`/${setLocaleInUrl}${slugify(
            item._rawLocaleTitle[locale]
          )}`}
          activeClassName="active"
          onClick={onNavigate}
        >
          {item._rawLocaleTitle[locale]}
        </Item>
      ))}
      <Footer>
        <Contact href={`tel:${sanityCompanyInfo.phone}`}>{sanityCompanyInfo.phone}</Contact>
        <Contact href={`mailto:${sanityCompanyInfo.email}`}>{sanityCompanyInfo.email}</Contact>
        <LanguageSelector locale={pageContext.locale} />
      </Footer>
      <ApplyNowDrawer locale={locale} />
    </Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query MobileNav {
        sanityAllVacanciesPage {
          id
          _rawLocaleTitle
        }
        sanityOurProcessPage {
          id
          _rawLocaleTitle
        }
        sanityAboutUsPage {
          id
          _rawLocaleTitle
        }
        sanityStoriesPage {
          id
          _rawLocaleTitle
        }
        sanityFaqPage {
          id
          _rawLocaleTitle
        }
        sanityContactPage {
          id
          _rawLocaleTitle
        }
        sanityCompanyInfo {
          phone
          email
        }
      }
    `}
    render={data => (
      <MobileNav data={data} {...props} />
    )}
  />
)
