import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const PrimaryBtn = styled.button`
  display: inline-block;
  border-radius: 4px;
  background-color: ${props => props.white ? props.theme.colors.white : props.theme.primaryColors.primary};
  padding: 16px 40px;
  width: 100%;
  color: ${props => props.white ? props.theme.primaryColors.primary : props.theme.colors.white};
  font-family: 'Montserrat',sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  transition: background-color ${props => props.theme.transitions.hover};
  border: none;
  &:hover {
    background-color: ${props => props.white ? props.theme.colors.black200 : props.theme.colors.orange900};
    cursor: pointer;
  }
  &:focus {
    background-color: ${props => props.white ? props.theme.colors.black200 : props.theme.colors.orange700};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.black300};
    cursor: not-allowed;
  }
  ${breakpoints.sm`
    width: auto;
    min-width: 160px;
  `};
`;

export default PrimaryBtn;

