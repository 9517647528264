import { css } from 'styled-components';

const breakpoints = {
  xs: (...args) => css`
  @media (min-width: 0px) {
    ${css(...args)}
  }
`,
  sm: (...args) => css`
    @media (min-width: 600px) {
      ${css(...args)}
    }
  `,
  md: (...args) => css`
  @media (min-width: 960px) {
    ${css(...args)}
  }
  `,
  lg: (...args) => css`
    @media (min-width: 1280px) {
      ${css(...args)}
    }
  `,
  xl: (...args) => css`
  @media (min-width: 1920px) {
    ${css(...args)}
  }
`,
};

export default breakpoints;
